import { f7 } from 'framework7-react';
import { useMemo, useState } from 'react';
import { useRepairProgressDataContext } from '../context/progressDetailContext';
import {
  useRepairRequestDataContext,
  useRepairRequestStateContext,
} from '../context/repairRequestContext';
import { usePostUpdateInsuranceInfo } from '../hook/api/usePostUpdateInsuranceInfo';
import { useInsuranceInfo } from '../hook/util/repairCheckIn/useInsuranceInfo';
import { InsuranceInfoType, RepairRequestDataType } from '../type/bodyAndPaint';
import { EntryUseInsuranceField } from '../util/addInsurance/entryUseInsurance';
import { ReEstimateButtonField } from '../util/addInsurance/reEstimateButtonField';
import { ModalSheet } from '../util/modalSheet';
import { EstimateResultInfoField } from '../util/repairRequest/estimateResultInfoField';
import { RepairRequestButtonField } from '../util/repairRequest/repairRequestButtonField';
import { paths } from '@/config/paths';

export const EstimateResultInformation = () => {
  const { data: bpData } = useRepairProgressDataContext();
  const { setState } = useRepairRequestStateContext();
  const { data } = useRepairRequestDataContext();

  const isEstimating = useMemo(
    () => data?.estimate_result_info?.is_estimating ?? true,
    [data],
  );
  const isInsurance = useMemo(
    () => !!data?.estimate_result_info?.insurance_apply_result,
    [data],
  );

  const [openInsuranceModal, setOpenInsuranceModal] = useState(false);
  const [stateInsurance, setInsurance, disable] = useInsuranceInfo({
    info: {} as InsuranceInfoType,
  });
  const { postRequest } = usePostUpdateInsuranceInfo({
    ids: {
      m_customer_id: bpData?.ids?.customerID ?? 0,
      t_pm_car_inspection_id: bpData?.ids?.inspectionID ?? 0,
      m_affiliate_id: bpData?.ids?.affiliateID ?? 0,
      t_body_paint_id: data?.ids?.bodyPaintID ?? 0,
    },
    insurance: stateInsurance,
  });

  const handleClickBack = () => {
    f7.views.main.router.navigate(paths.maintenance);
  };
  const handleClickCheckImage = () => {
    setState('ESTIMATE_IMAGE');
  };

  const handleClickRepairItemSelect = () => {
    setState('REPAIR_SELECT');
  };
  const handleClickNoRepair = () => {
    // TODO: 遷移先未定
    f7.views.main.router.navigate(paths.maintenance);
  };
  const handleClickAddInsurance = async () => {
    postRequest().then(() => {
      setOpenInsuranceModal(false);
    });
  };

  return (
    <>
      <ModalSheet
        title={'保険の登録'}
        open={openInsuranceModal}
        onClose={() => setOpenInsuranceModal(false)}
      >
        <EntryUseInsuranceField info={stateInsurance} setInfo={setInsurance} />
        <ReEstimateButtonField
          disable={disable}
          onClickAddInsurance={handleClickAddInsurance}
          onClickEntryCancel={() => setOpenInsuranceModal(false)}
        />
      </ModalSheet>
      <EstimateResultInfoField
        onCheckImage={handleClickCheckImage}
        info={data ?? ({} as RepairRequestDataType)}
      />
      <RepairRequestButtonField
        isEstimating={isEstimating}
        isInsurance={isInsurance}
        onClickRepairSelect={handleClickRepairItemSelect}
        onClickAddInsurance={() => setOpenInsuranceModal(true)}
        onClickNoRepair={handleClickNoRepair}
      />
    </>
  );
};
