import { baseApi } from '@/config/axios';
import {
  BodyAndPaintDetailParams,
  BodyAndPaintRepairRequestParams,
  BodyAndPaintRepairRequestResponse,
  CancelRepairCheckInReserveParams,
  CancelRepairCheckInReserveResponse,
  ChangeRepairCheckInDateParams,
  ChangeRepairCheckInDateResponse,
  DamagePartImageParams,
  DamagePartImageResponse,
  InsuranceCompanyListParams,
  InsuranceCompanyListResponse,
  MonthlyCheckInReserveParams,
  MonthlyCheckInReserveResponse,
  RepairCheckInReserveParams,
  RepairCheckInReserveResponse,
  RepairedPickUpReserveParams,
  RepairedPickUpReserveResponse,
  RepairEstimateResultParams,
  RepairFactoryListParams,
  RepairFactoryListResponse,
  RepairNotificationParams,
  RepairNotificationResponse,
  UpdateInsuranceInfoParams,
  UpdateInsuranceInfoResponse,
} from '@/types/api/bodyAndPaint';

// 入庫予約（傷・凹み修理）状況取得（1ヶ月） API MonthlyCheckInReserve
export const getMonthlyCheckInReserveApi = (
  params: MonthlyCheckInReserveParams,
): Promise<MonthlyCheckInReserveResponse> =>
  baseApi.request({
    method: 'get',
    url: 'v1/customer/repair/warehousing/reservation_month',
    params,
  });
// 入庫予約（傷・凹み修理）送信 API RepairCheckInReserve
export const postRepairCheckInReserveApi = (
  params: RepairCheckInReserveParams,
): Promise<RepairCheckInReserveResponse> =>
  baseApi.request({
    method: 'post',
    url: 'v1/customer/repair/warehousing/reservation',
    params,
  });
// 鈑金予約情報取得 API BodyAndPaintDetail
export const getBodyAndPaintDetailApi = (
  params: BodyAndPaintDetailParams,
): Promise<any> =>
  baseApi.request({
    method: 'get',
    url: 'v1/customer/repair/warehousing/reservation',
    params,
  });
// 入庫予定日（傷・凹み修理）変更 API ChangeRepairCheckInDate
export const postChangeRepairCheckInDateApi = (
  params: ChangeRepairCheckInDateParams,
): Promise<ChangeRepairCheckInDateResponse> =>
  baseApi.request({
    method: 'post',
    url: 'v1/customer/repair/warehousing/reservation/update_car_inspection',
    params,
  });
// 入庫予約（傷・凹み修理）取消 API CancelRepairCheckInReserve
export const postCancelRepairCheckInReserveApi = (
  params: CancelRepairCheckInReserveParams,
): Promise<CancelRepairCheckInReserveResponse> =>
  baseApi.request({
    method: 'post',
    url: 'v1/customer/repair/warehousing/reservation/cancel',
    params,
  });
// 修理見積（傷・凹み修理）結果情報取得 API RepairEstimateResult
export const getRepairEstimateResultApi = (
  params: RepairEstimateResultParams,
): Promise<any> =>
  baseApi.request({
    method: 'get',
    url: 'v1/customer/repair/estimates',
    params,
  });
// 修理見積（傷・凹み修理）保険情報更新 API UpdateInsuranceInfo
export const postUpdateInsuranceInfoApi = (
  params: UpdateInsuranceInfoParams,
): Promise<UpdateInsuranceInfoResponse> =>
  baseApi.request({
    method: 'put',
    url: 'v1/customer/repair/insurance',
    params,
  });
// 修理（傷・凹み修理）依頼送信 API RepairRequest
export const postRepairRequestApi = (
  params: BodyAndPaintRepairRequestParams,
): Promise<BodyAndPaintRepairRequestResponse> =>
  baseApi.request({
    method: 'post',
    url: 'v1/customer/repair/request',
    params,
  });
// 損傷個所画像取得 API DamagePartImage
export const getDamagePartImageApi = (
  params: DamagePartImageParams,
): Promise<DamagePartImageResponse> =>
  baseApi.request({
    method: 'get',
    url: 'v1/customer/repair/warehousing/injury_place',
    params,
  });
// 鈑金作業一覧取得 API RepairWorkInProgress
export const getRepairWorkInProgressApi = (
  params: DamagePartImageParams,
): Promise<DamagePartImageResponse> =>
  baseApi.request({
    method: 'get',
    url: 'v1/customer/repair/warehousing/work_list',
    params,
  });
// 保険会社一覧取得 API InsuranceCompanyList
export const getInsuranceCompanyListApi = (
  params: InsuranceCompanyListParams,
): Promise<InsuranceCompanyListResponse> =>
  baseApi.request({
    method: 'get',
    url: 'v1/customer/repair/insurance/company_list',
    params,
  });
// 修理工場一覧取得 API RepairFactoryList
export const getRepairFactoryListApi = (
  params: RepairFactoryListParams,
): Promise<RepairFactoryListResponse> =>
  baseApi.request({
    method: 'get',
    url: 'v1/customer/repair/plant_list',
    params,
  });

// 納車返却日（傷・凹み修理）予約送信 API RepairedPickUpReserve //TODO:
export const postRepairedPickUpReserveApi = (
  params: RepairedPickUpReserveParams,
): Promise<RepairedPickUpReserveResponse> =>
  baseApi.request({
    method: 'post',
    url: '',
    params,
  });
// お知らせ API RepairNotification //TODO:
export const getRepairNotificationApi = (
  params: RepairNotificationParams,
): Promise<RepairNotificationResponse> =>
  baseApi.request({
    method: 'get',
    url: '未定：通知仕様が不明',
    params,
  });
