import { useCallback, useEffect, useMemo, useState } from 'react';
import { DAMAGE_PART } from '../../../const/bodyAndPaint';
import {
  EstimateDetailItemItemType,
  EstimateImageInfoType,
  EstimateSubjectItemItemType,
  InsuranceApplyResultItemType,
  RepairItemType,
  RepairRequestDataType,
} from '../../../type/bodyAndPaint';
import { useGetDamagePartImageData } from '../../api/useGetDamagePartImageData';
import { useGetEstimateResultData } from '../../api/useGetEstimateResultData';

interface RepairRequestDataHookProps {
  m_customer_id: number;
  t_pm_car_inspection_id: number;
  t_sale_id: number;
  status: number;
  factory_name: string;
  car_info: {
    car_name: string;
    number_plate: string;
    vin_number: string;
    check_in_date: Date;
  };
}
export const useRepairRequestData = ({
  m_customer_id,
  t_pm_car_inspection_id,
  t_sale_id,
  status,
  factory_name,
  car_info,
}: RepairRequestDataHookProps) => {
  const { estimateInfo, estimateItemList } = useGetEstimateResultData({
    m_customer_id,
    t_pm_car_inspection_id,
    t_sale_id,
  });
  const estimateID = useMemo(() => estimateInfo?.t_estimate_id, [estimateInfo]);
  const bodyPaintID = useMemo(
    () => estimateInfo?.t_body_paint_id,
    [estimateInfo],
  );
  const { damagePartList } = useGetDamagePartImageData({
    t_pm_car_inspection_id,
  });

  // ID情報
  const idInformation = useMemo(() => {
    return {
      estimateID,
      bodyPaintID,
    };
  }, [bodyPaintID, estimateID]);
  // 保険申請結果
  const applyResult = useMemo(() => {
    const isInsurance = estimateInfo?.insurance_use_flg;
    if (isInsurance) {
      return {
        repair_estimate_amount: estimateInfo.total_price,
        insurance_applied_amount: estimateInfo.total_confirmed_price,
        total_loss_determination: !!estimateInfo.total_loss_judge_flg,
        deductible_amount: estimateInfo.deductible_fee,
        total_repair_amount:
          estimateInfo.total_price - estimateInfo.total_confirmed_price,
      } as InsuranceApplyResultItemType;
    } else {
      return undefined;
    }
  }, [estimateInfo]);
  //　 見積盛り項目リスト
  const estimateList = useMemo(
    () =>
      estimateItemList?.reduce((list, item) => {
        const estimateItem: EstimateDetailItemItemType = {
          id: item?.t_pm_car_inspection_need_maintenance_id,
          name: item?.t_pm_car_inspection_need_maintenance_name,
          isInsurance: item?.is_insurance_applicable,
          repair_amount: item?.subtotal,
          applied_amount: item?.confirmed_price,
        };
        list.push(estimateItem);
        return list;
      }, [] as EstimateDetailItemItemType[]),
    [estimateItemList],
  );
  // 見積もり情報
  const estimateInformation: EstimateSubjectItemItemType = useMemo(
    () => ({
      created_at: estimateInfo?.issue_date,
      estimate_number: estimateInfo?.unique_code
        ? `${estimateInfo?.unique_code} ${estimateInfo?.estimate_version}`
        : '-',
      car_name: car_info?.car_name,
      number_plate: car_info?.number_plate,
      vin_number: car_info?.vin_number,
      check_in_date: new Date(car_info?.check_in_date),
    }),
    [estimateInfo, car_info],
  );

  // 損傷画像リスト
  const estimateImageList: EstimateImageInfoType[] = useMemo(
    () =>
      damagePartList?.reduce((list, item) => {
        const imageItem = item as { [key: string]: any };

        const damagePart = Object.keys(DAMAGE_PART)
          .filter((key) => key in imageItem && imageItem[key] === true)
          .map((key) => DAMAGE_PART[key as keyof typeof DAMAGE_PART]);

        const repairList = item?.maintenance_lists.map(
          (item) => item.maintenance_name,
        );
        const imageList = item?.image_lists.map((item) => item.image_file_path);
        const estimateItem: EstimateImageInfoType = {
          damage_part: damagePart,
          damage_desc: item?.damage_detail,
          repair_item_list: repairList,
          image_url_list: imageList,
        };
        list.push(estimateItem);
        return list;
      }, [] as EstimateImageInfoType[]) ?? [],
    [damagePartList],
  );

  const repairItemList = useMemo(
    () =>
      estimateItemList?.reduce((list, item) => {
        const repairItem: RepairItemType = {
          id: item.t_pm_car_inspection_need_maintenance_id,
          repair_item_name: item.t_pm_car_inspection_need_maintenance_name,
          repair_select: undefined,
          is_insurance: item.is_insurance_applicable,
          repair_amount: item.subtotal,
          applied_amount: item.confirmed_price,
        };
        list.push(repairItem);
        return list;
      }, [] as RepairItemType[]) ?? [],
    [estimateItemList],
  );

  const estimateStatus = 5;
  const [isEstimating] = useState(!!(status < estimateStatus));
  const [repairRequestData, setRepairRequestData] =
    useState<RepairRequestDataType>({} as RepairRequestDataType);
  const initialize = useCallback(() => {
    setRepairRequestData({
      ids: idInformation,
      estimate_result_info: {
        is_estimating: isEstimating,
        insurance_apply_result: applyResult,
        estimate_detail_item_list: estimateList,
        estimate_subject_item_info: estimateInformation,
      },
      estimate_image_list: estimateImageList,
      repair_item_select_info: {
        repair_item_info_list: repairItemList,
        repair_item_total_amount: 0,
      },
      repair_item_confirm_info: {
        repair_request_info: {
          car_name: car_info.car_name,
          category: '傷・凹み修理',
          factory_name: factory_name,
          total_amount: undefined,
        },
        repair_request_select_info_list: [],
      },
    });
  }, [
    isEstimating,
    applyResult,
    factory_name,
    idInformation,
    car_info.car_name,
    estimateImageList,
    estimateInformation,
    estimateList,
    repairItemList,
  ]);
  useEffect(() => {
    initialize();
  }, [JSON.stringify(estimateInformation)]);
  return { repairRequestData };
};
